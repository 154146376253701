<template>
	<div class="container mx-auto mt-10 sm:mt-20">
		<!-- Project header -->
		<SingleProjectHeader />

		<!-- Project gallery -->
		<SingleProjectGallery />

		<!-- Project information -->
		<SingleProjectInfo />

		<!-- Project related projects -->
		<!-- <SingleProjectRelatedProjects /> -->
	</div>
</template>

<script>
import SingleProjectHeader from '@/components/project/military-defense/SingleProjectHeader';
import SingleProjectGallery from '@/components/project/military-defense/SingleProjectGallery';
import SingleProjectInfo from '@/components/project/military-defense/SingleProjectInfo';
import feather from 'feather-icons';

export default {
	name: 'Projects',
	components: {
		SingleProjectHeader,
		SingleProjectGallery,
		SingleProjectInfo,
	},
	data: () => {
		return {};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<style scoped></style>
